<template>
  <div class="view pa24">
    <div class="mb20">
      <el-tabs v-model="contentType" @tab-click="tabClickContentType">
        <el-tab-pane :label="name" :name="id" :key="id" :disabled="loading" v-for="(name, id) of typeData"></el-tab-pane>
      </el-tabs>
    </div>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
      <el-tabs v-show="contentType == 1 && memberTypeData.length>0" v-model="ruleForm.level" type="border-card"
        @tab-click="tabClickLevel">
        <el-tab-pane v-for="(row, index) of memberTypeData" :label="row.levelName" :disabled="loading" :name="row.levelId" :key="index">
          <el-row>
            <el-col :md="11">
              <el-form-item label="标题" prop="title">
                <el-input v-model="ruleForm.title"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="承诺内容" prop="content">
            <quill-editor style="height: 100%" v-model="ruleForm.content" ref="myQuillEditor" :options="editorOption" />
          </el-form-item>
          <span class="flex-c-c">
            <el-button type="primary" @click="submitForm" :loading="loadingBtn">提 交</el-button>
          </span>
        </el-tab-pane>
      </el-tabs>
      <h4 class="flex-c-c" v-if="memberTypeData.length==0 && contentType == 1">请先添加会员职务</h4>
      <el-tabs v-show="contentType == 2 && memberLevelData.length>0" v-model="ruleForm.level" type="border-card"
        @tab-click="tabClickLevel">
        <el-tab-pane v-for="(row, index) of memberLevelData" :label="row.goodsName" :disabled="loading" :name="row.goodsId" :key="index">
          <el-row>
            <el-col :md="11">
              <el-form-item label="标题" prop="title">
                <el-input v-model="ruleForm.title"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="承诺内容" prop="content">
            <quill-editor style="height: 100%" v-model="ruleForm.content" ref="myQuillEditor" :options="editorOption" />
          </el-form-item>

          <span class="flex-c-c">
            <el-button type="primary" @click="submitForm" :loading="loadingBtn">提 交</el-button>
          </span>
        </el-tab-pane>
      </el-tabs>
      <h4 class="flex-c-c" v-if="memberLevelData.length==0 && contentType == 2">请先添加分销等级</h4>
      <div v-show="contentType != 1 && contentType != 2">
        <el-row>
          <el-col :md="11">
            <el-form-item label="标题" prop="title">
              <el-input v-model="ruleForm.title"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="承诺内容" prop="content">
          <quill-editor style="height: 100%" v-model="ruleForm.content" ref="myQuillEditor" :options="editorOption" />
        </el-form-item>
        <span class="flex-c-c">
          <el-button type="primary" @click="submitForm" :loading="loadingBtn">提 交</el-button>
        </span>
      </div>
    </el-form>
  </div>
</template>

<script>
import { quillEditor, Quill } from "vue-quill-editor"; //
import resizeImage from "quill-image-resize-module"; // 调整大小组件。
import { ImageDrop } from "quill-image-drop-module"; // 拖动加载图片组件
import quillConfig from "@/utils/quill-config.js";
Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/resizeImage ", resizeImage);
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
quillConfig.placeholder = '请填写承诺内容'
var Align = Quill.import('attributors/style/align');
Align.whitelist = ['right', 'center', 'justify'];
Quill.register(Align, true);

import { getAgreeMessage, setAgreeMessage } from "@/api/association"
import { getGoodsMemberLinkList, getAssociationMemberLevelList } from "@/api/associationMember";
// import customPopconfirm from "@/components/common/customPopconfirm";
export default {
  name: "termSetting",
  components: {
    quillEditor,
    // customPopconfirm
  },
  data() {
    return {
      //新增/修改配置
      editorOption: quillConfig,
      typeData: {
        1: "购买会员",
        2: "购买分销等级",
        3: "商家进驻",
        4: "服务大厅进驻",
      },
      disabled: false,
      ruleForm: {
        title: "",
        content: "",
        level: "",
      },
      contentType: "1",
      rules: {//提交验证
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        content: [{ required: true, message: "请输入承诺内容" }],
      },
      updateId: 0,//修改id
      //列表配置
      loading: false, //表格加载
      userInfo: this.$store.state.loginRoot.userInfo,
      associationId: 0,
      loadingBtn: false,
      memberLevelData: [],
      memberTypeData: [],
    };
  },
  computed: {
    watchAssociation() {
      return this.$store.state.checkedAssociation
    },
  },
  watch: {
    watchAssociation(row) {
      this.associationId = row.associationId;
      this.initData().then(() => {
        this.tabClickContentType();
      });
    }
  },
  filters: {

  },
  created() {
    let checkedAssociation = sessionStorage.getItem('checkedAssociation');
    this.associationId = JSON.parse(checkedAssociation).associationId;
    this.loading = true;
    this.initData().then(() => {
      this.tabClickContentType();
    });
  },
  methods: {
    initData() {
      return Promise.all([
        new Promise(open => {
          getGoodsMemberLinkList({
            accountType: 9,
            isPage: 0
          }).then(res => {
            if (res.data) {
              let memberLevelData = [];
              for (let row of res.data) {
                let obj = {
                  ...row,
                  goodsId: String(row.goodsId)
                };
                memberLevelData.push(obj)
              }
              this.memberLevelData = memberLevelData
            }
            open();
          }).catch(err => {
            if (err.code === 201) {
              this.$message.error(err.message)
            }
            open();
          })
        }),
        new Promise(open => {
          getAssociationMemberLevelList({
            associationId: this.associationId,
            levelType: 1
          }).then(res => {
            if (res.data) {
              let memberTypeData = [];
              for (let row of res.data) {
                let obj = {
                  ...row,
                  levelId: String(row.levelId)
                };
                memberTypeData.push(obj)
              }
              this.memberTypeData = memberTypeData
            }
            open();
          }).catch(err => {
            if (err.code === 201) {
              this.$message.error(err.message)
            }
            open();
          })
        })
      ])
    },
    /**@method 提交内容 */
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let params = {
            ...this.ruleForm,
            associationId: this.associationId,
            contentType: this.contentType
          };
          if(this.contentType>=3){
            delete params.level
          }
          this.loadingBtn = true;
          setAgreeMessage(params).then(res => {
            this.loadingBtn = false;
            if (res.code === 200) {
              this.$message({
                message: "编辑成功",
                type: "success"
              })
              this.getData();
            } else {
              this.$message.error(err.message)
            }
          }).catch(err => {
            this.loadingBtn = false;
            if (err.code === 201) {
              this.$message.error(err.message)
            }
          })
        } else {
          //-console.log("error submit!!");
          return false;
        }
      });
    },
    /**@method 切换条款类型 */
    tabClickContentType() {
      if (this.contentType == 1) {
        if (this.memberTypeData.length > 0) {
          this.ruleForm.level = this.memberTypeData[0].levelId;
        }
      }
      if (this.contentType == 2) {
        if (this.memberLevelData.length > 0) {
          this.ruleForm.level = this.memberLevelData[0].goodsId
        }
      }
      this.getData();
    },
    /**@method 切换等级/套餐 */
    tabClickLevel() {
      this.getData();
    },
    /**@method 获取列表 */
    async getData() {
      let params = {
        contentType: this.contentType,
        associationId: this.associationId
      };
      if (this.contentType == 1 || this.contentType == 2) {
        params.level = this.ruleForm.level;
      }
      try {
        this.loading = true;
        let res = await getAgreeMessage(params);
        this.loading = false;

        const { data } = res;
        this.ruleForm = {
          title: data.title,
          content: data.content,
          level: data.level ? String(data.level) : this.ruleForm.level
        }
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.switchInput {
  display: flex;

  .selectType {
    width: 150px;
  }
}

.operation-left {
  margin-bottom: 20px;

  .el-button {
    width: 95px;
    height: 32px;
    background: #51cbcd;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;

    &:active {
      border-color: #51cbcd;
    }
  }
}
</style>